<template>
  <div>
    <b-card>
      <b-row class="justify-content-end">
        <b-col
          md="auto"
        >
          <b-button
            variant="primary"
            @click="showModal()"
          >
            Nouvelle compétence
          </b-button>
        </b-col>
      </b-row>
      <CustomFilter
        prop-module-name="compétence"
      />
      <b-row class="justify-content-end">
        <b-col
          md="auto"
        >
          <b-button
            variant="primary"
            @click="onSearch"
          >
            Rechercher
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :pagination-options="{
          enabled: true,
          nextLabel: 'Suivant',
          prevLabel: 'Précédent',
          rowsPerPageLabel: 'Lignes par page'
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span
            v-if="props.column.field == 'update'"
          >
            <b-button
              id="detailIcon"
              variant="primary"
              @click="showModal(props.row)"
            >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>
            <!-- <ModalUpdate /> -->
          </span>
          <span v-else-if="props.column.field == 'actif'">
            <b-badge
              v-if="props.formattedRow[props.column.field]"
              variant="success"
            >
              Actif
            </b-badge>
            <b-badge
              v-else
              variant="danger"
            >
              Inactif
            </b-badge>
          </span>
        </template>
      </vue-good-table>
    </b-card>

    <b-modal
      id="modal-update"
      v-model="modalShow"
      centered
      :title="modalTitle"
      ok-title="Enregistrer"
      cancel-title="Annuler"
      cancel-variant="outline-secondary"
      @ok="submitForm"
    >
      <validation-observer ref="observer">
        <b-form>
          <b-form-group
            label="Intitulé"
          >
            <validation-provider
              #default="{ errors }"
              name="intitule"
              rules="required|maxLengthIntitule"
            >
              <b-form-input
                id="intitule"
                v-model="defaultIntitule"
                placeholder="Intitulé"
                name="intitule"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
          <b-form-group
            label="Abrévation"
          >
            <validation-provider
              #default="{ errors }"
              name="abreviation"
              rules="required|maxLengthAbreviation"
            >
              <b-form-input
                id="abreviation"
                v-model="defaultAbreviation"
                placeholder="Abrévation"
                name="abreviation"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
          <b-form-group
            label="Documentation"
          >
            <validation-provider
              #default="{ errors }"
              name="documentation"
            >
              <b-form-input
                id="documentation"
                v-model="defaultDocumentation"
                placeholder="Documentation"
                name="documentation"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
          <b-form-group
            v-if="fromRow"
            label="Statut"
          >
            <b-form-checkbox
              v-model="checked"
              checked="true"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span>{{ isChecked }}</span>
            </b-form-checkbox>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BForm, BFormInput, BFormGroup,
  BCard, BButton, BCol, BRow, BBadge, VBModal, BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import {
  ValidationProvider, ValidationObserver, localize, validate,
} from 'vee-validate'
import fr from 'vee-validate/dist/locale/fr.json'
import {
  required, email, min, max_value,
} from '@validations'
import CustomFilter from './customFilter.vue'

localize('fr', fr)
// import ModalUpdate from './modalUpdate.vue'

export default {
  components: {
    BCard,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    BBadge,
    BCol,
    BRow,
    VueGoodTable,
    CustomFilter,
    ValidationProvider,
    ValidationObserver,
    // ModalUpdate,
  },
  data() {
    return {
      fromRow: false,
      rowId: '',
      defaultIntitule: '',
      defaultAbreviation: '',
      defaultDocumentation: 'Mode opératoire interne, guide technique pour la réception des réseaux d\'assainissement neufs de 2014, XXXXXXXXXX, XXXXXXXXXXX',
      modalShow: false,
      modalTitle: '',
      checked: false,
      columns: [
        {
          field: 'update',
          sortable: false,
          tdClass: 'text-center',
        },
        {
          label: 'Date de création',
          field: 'createdAt',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSXXX',
          dateOutputFormat: 'dd/MM/yyyy',
          tdClass: 'text-left',
          thClass: 'text-left',
        },
        { label: 'Nom de la compétence', field: 'libelle_competence' },
        { label: 'Abrévation', field: 'abreviation' },
        { label: 'Statut', field: 'actif', type: 'boolean' },
      ],
      rows: [],
    }
  },
  computed: {
    isChecked() {
      return this.checked ? 'actif' : 'inactif'
    },
  },
  beforeMount() {
    this.$store.dispatch('fonctionCompetence/getAllCompetences').then(response => {
      this.rows = response
    })
  },
  methods: {
    alertModal(params) {
      this.$swal({
        title: params.title,
        text: params.text,
        icon: params.icon,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    submitForm() {
      const params = {
        libelle_competence: this.defaultIntitule,
        abreviation: this.defaultAbreviation,
        documentation: this.defaultDocumentation,
        actif: this.checked ? 1 : 0,
      }
      console.log(params)
      this.$refs.observer.validate().then(success => {
        if (success) {
          if (this.fromRow) {
            params.id = this.rowId
            this.$store.dispatch('fonctionCompetence/updateCompetence', params).then(res => {
              this.alertModal({ title: 'Mise à jour réussie', text: 'Les informations ont été mises à jour', icon: 'success' })
              this.$store.dispatch('fonctionCompetence/getAllCompetences').then(response => {
                this.rows = response
              })
            }).catch(error => {
              this.alertModal({ title: 'Erreur', text: error.message, icon: 'error' })
            })
          } else {
            this.$store.dispatch('fonctionCompetence/createCompetence', params).then(res => {
              this.alertModal({ title: 'Ajout réussi', text: 'Une nouvelle compétence a été ajoutée', icon: 'success' })
              this.$store.dispatch('fonctionCompetence/getAllCompetences').then(response => {
                this.rows = response
              })
            }).catch(error => {
              this.alertModal({ title: 'Erreur', text: error.message, icon: 'error' })
            })
          }
        } else {
          this.modalShow = true
          console.log(success)
        }
      })
    },
    showModal(row) {
      if (row) {
        this.fromRow = true
        this.modalTitle = 'Modification de la compétence'
        this.rowId = row.id
        this.defaultIntitule = row.libelle_competence
        this.defaultAbreviation = row.abreviation
        this.defaultDocumentation = row.documentation
        this.checked = row.actif
      } else {
        this.fromRow = false
        this.modalTitle = "Ajout d'une nouvelle compétence"
        this.rowId = null
        this.defaultIntitule = ''
        this.defaultAbreviation = ''
        this.defaultDocumentation = 'Mode opératoire interne, guide technique pour la réception des réseaux d\'assainissement neufs de 2014, XXXXXXXXXX, XXXXXXXXXXX'
        this.checked = false
      }
      this.modalShow = !this.modalShow
    },
    onSearch() {
      const params = this.$store.getters['customFilter/getFilter']
      this.$store.dispatch('fonctionCompetence/getAllCompetences', params).then(response => {
        this.rows = response
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

#detailIcon {
  cursor: pointer
}
</style>
