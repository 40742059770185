<template>
  <div>
    <!-- <b-row class="justify-content-end">
      <b-col
        md="auto"
      >
        <b-button
          variant="primary"
        >
          Nouvelle {{ propModuleName }}
        </b-button>
      </b-col>
    </b-row> -->
    <b-row>
      <b-col md="4">
        <b-form-group>
          <label>Nom de la {{ propModuleName }}</label>
          <b-form-input
            v-model="filter.libelle"
            :placeholder="moduleName"
            type="text"
            class="d-inline-block"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <label>Statut</label>
          <v-select
            v-model="filter.actif"
            label="societe"
            placeholder="Actif / Inactif"
            :options="['Actif', 'Inactif']"
          >
            <div slot="no-options">
              Option inexistante
            </div>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- <b-row class="justify-content-end">
      <b-col
        md="auto"
      >
        <b-button
          variant="primary"
          @click="onSearch"
        >
          Rechercher
        </b-button>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BFormGroup,
    BFormInput,
    // BButton,
    BRow,
    BCol,
  },
  props: {
    propModuleName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      moduleName: `nom de la ${this.propModuleName}`,
      filter: {
        actif: '',
        libelle: '',
      },
    }
  },
  watch: {
    filter: {
      handler(newVal, oldVal) {
        this.$store.dispatch('customFilter/setFilter', this.filter)
      },
      deep: true,
    },
  },
}
</script>

<style>

</style>
